import classNames from 'classnames';
import { useId } from 'react';
import { MaterialRadioCheckedSvg, MaterialRadioUncheckedSvg } from '../../exports/icons';
import { RadioProps } from './Radio.types';

/**
 * A Radio component allows users to select one option from a set.
 *
 * @example
 * <Radio label="Option 1" name="option" value="1" isChecked={true} onChange={handleChange} isDisabled={false} isFormValidated={false} isRequired={false} />
 */
export function Radio({
  id,
  label,
  subLabel,
  name,
  value,
  isChecked,
  isDisabled,
  isRequired,
  isVerticalSubLabel,
  actionableElement,
  isError,
  onChange,
  'data-testid': dataTestId,
}: RadioProps): JSX.Element {
  const radioUniqueId = useId();
  const inputId = id || radioUniqueId;

  const labelStyle = classNames('text-dt-theme-text-radio-button-radio-label mr-8');
  const subLabelStyle = classNames('text-dt-theme-text-radio-button-radio-sublabel', {
    'mt-4': isVerticalSubLabel,
  });

  return (
    <div
      data-testid={dataTestId}
      className={classNames('flex items-center w-auto h-auto font-hind', {
        'opacity-dt-opacity-50': isDisabled,
      })}
    >
      <div className={classNames('relative self-start min-w-[22px] w-[22px] h-[22px] box-border mr-16')}>
        <input
          id={inputId}
          type="radio"
          aria-checked={isChecked}
          name={name}
          checked={isChecked}
          value={value}
          disabled={isDisabled}
          onChange={onChange}
          required={isRequired}
          tabIndex={-1}
          className={classNames('flex appearance-none w-full h-full m-0 self-end', {
            'cursor-pointer': !isDisabled,
            'cursor-not-allowed': isDisabled,
          })}
        />
        {!isChecked && (
          <MaterialRadioUncheckedSvg
            className={classNames(
              'pointer-events-none absolute w-full h-full top-0 left-0 text-dt-theme-border-radio-button-radio-button',
              {
                'text-dt-theme-border-radio-button-radio-button-error': isError,
                'cursor-pointer': !isDisabled,
                'cursor-not-allowed': isDisabled,
              }
            )}
            aria-hidden
          />
        )}
        {isChecked && (
          <MaterialRadioCheckedSvg
            className={classNames(
              'pointer-events-none absolute w-full h-full top-0 left-0 fill-dt-theme-background-radio-button-radio-button',
              {
                'cursor-pointer': !isDisabled,
                'cursor-not-allowed': isDisabled,
              }
            )}
            aria-hidden
          />
        )}
      </div>
      <div className={classNames('flex flex-col xs:flex-row items-start xs:items-center w-full')}>
        <label
          htmlFor={inputId}
          className={classNames('flex select-none text-16 self-start', {
            'flex-row': !isVerticalSubLabel,
            'flex-col': isVerticalSubLabel,
            'cursor-pointer': !isDisabled,
            'cursor-not-allowed': isDisabled,
          })}
        >
          {/* Label */}
          {label && <span className={labelStyle}>{label}</span>}

          {/* SubLabel */}
          {subLabel && <span className={subLabelStyle}>{subLabel}</span>}
        </label>
        {actionableElement && isChecked && (
          <div className={classNames('ml-0 mt-16 xs:ml-auto xs:mt-0 xs:pl-16')}>{actionableElement}</div>
        )}
      </div>
    </div>
  );
}
