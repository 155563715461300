import { fetch as fetchLib, FetchOptions } from '@canalplus/mycanal-fetch';
import { getPublicConfig } from './config/config-helper';

const { mock } = getPublicConfig();

const baseFetchOptions: FetchOptions = {
  credentials: mock ? 'include' : 'same-origin',
};

export function fetch(url: string, options: FetchOptions = {}): Promise<Response> {
  return fetchLib(url, { ...options, ...baseFetchOptions });
}
