import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Radio } from '../Radio';
import { RadioGroupProps } from './RadioGroup.types';

/**
 * A RadioGroup component allows users to select one option from a set of radio buttons.
 * The value must be distinct for each option, in order to select only one option at start.
 *
 * @example
 * <RadioGroup options={[{ label: 'Option 1', name: 'option', value: '1' }, ...]} label="Sample Group" onChange={handleChange} />
 */
export function RadioGroup({
  id,
  options,
  label,
  onChange,
  className,
  'data-testid': dataTestId,
}: RadioGroupProps): JSX.Element {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  /** Initializes the selected value with the first checked option */
  useEffect(() => {
    const index = options.findIndex((option) => option.isChecked);
    const initialSelectedValue = index !== -1 ? options[index]?.value : options[0].value;
    setSelectedValue(initialSelectedValue);
  }, [options]);

  const handleOptionClick = (value: string) => {
    setSelectedValue(value);
    onChange(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const currentIndex = options.findIndex((option) => option.value === selectedValue);
    let newIndex = currentIndex;

    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowRight':
        newIndex = (currentIndex + 1) % options.length;
        e.preventDefault();
        break;
      case 'ArrowUp':
      case 'ArrowLeft':
        newIndex = (currentIndex - 1 + options.length) % options.length;
        e.preventDefault();
        break;
      default:
        return;
    }

    const newValue = options[newIndex].value;
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const groupLabelId = `${id}_label`;

  return (
    <div
      data-testid={dataTestId}
      role="radiogroup"
      aria-labelledby={groupLabelId}
      id={id}
      tabIndex={0}
      aria-activedescendant={selectedValue ? `${id}_${selectedValue}` : `${id}_${options[0].value}`}
      className={classNames(
        'font-hind focus-visible:outline-offset-4 focus-visible:outline',
        'focus-visible:outline-dt-theme-border-radio-button-radio-button'
      )}
      onKeyDown={(e) => handleKeyDown(e)}
    >
      {label && (
        <h3 className="text-dt-theme-text-radio-button-radio-label" id={groupLabelId}>
          {label}
        </h3>
      )}
      {options.map((option) => (
        <div key={option.value} className={className}>
          <Radio
            {...option}
            id={`${id}_${option.value}`}
            isChecked={selectedValue === option.value}
            onChange={() => handleOptionClick(option.value)}
          />
        </div>
      ))}
    </div>
  );
}
