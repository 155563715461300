import { Template } from '@canalplus/sdk-hodor';
import { ApiV2ContextualOfferPage, ApiV2PaymentMeans } from '@dce-front/hodor-types';
import { keepPreviousData } from '@tanstack/react-query';
import classNames from 'classnames';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ErrorTemplate from '../../../components/Error/ErrorTemplate';
import Linker from '../../../components/Linker/Linker';
import { getPublicConfig } from '../../../helpers/config/config-helper';
import { useOnFocusable } from '../../../helpers/hooks/useOnFocusable';
import { useQueryTemplate } from '../../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import { FromProp } from '../../../server/modules/fetchWithQuery/types';
import { FetchRequestTypes } from '../../../services/types';
import { isImmersiveSelector } from '../../../store/slices/immersive-selectors';
import { FunnelHodorStep, FunnelInnerStep } from '../stores/constants';
import { setIsLoading, setIsOpen, setPreviousPage } from '../stores/funnel/actions';
import { useFunnelCurrentStep, useFunnelDispatch, useFunnelErrorTemplate } from '../stores/funnel/hooks';
import { ContextualOffer } from './ContextualOffer/ContextualOffer';
import styles from './FunnelContent.css';
import { PaymentMeans } from './PaymentMeans/PaymentMeans';
import { useHandleFunnelPath } from './hooks/useHandleFunnelPath/useHandleFunnelPath';

type FunnelContentProps = {
  /** Function to focus on the first focusable element */
  onFocusable?: () => void;
} & FromProp;

function FunnelContent({ from, onFocusable }: FunnelContentProps): JSX.Element | null {
  const isImmersive = useSelector(isImmersiveSelector);

  // Funnel hooks
  const funnelDispatch = useFunnelDispatch();
  const currentStep = useFunnelCurrentStep();
  const currentTemplate =
    currentStep.hodorStep === FunnelHodorStep.ContextualOffer
      ? FetchRequestTypes.ContextualOffer
      : FetchRequestTypes.PaymentMeans;
  const errorTemplate = useFunnelErrorTemplate();
  // Configurable timeout for funnel content
  const funnelContentTimeout = getPublicConfig().api.paymentProvider.timeout.funnelContent;

  const isTermsOfSale = currentStep.innerStep === FunnelInnerStep.TermsOfSale;

  const [{ data, error, isFetching, isLoading }] = useQueryTemplate<ApiV2ContextualOfferPage | ApiV2PaymentMeans>(
    currentStep.url,
    {
      from,
      template: FetchRequestTypes.ContextualOffer,
      fetchOptions: { timeout: funnelContentTimeout },
      options: { queryKeyPrefix: currentTemplate },
    },
    {
      // data is keep for mount data component
      placeholderData: keepPreviousData,

      // enabled fetching only if url exist (step without url should not be called)
      enabled: !!currentStep.url,
    }
  );

  useHandleFunnelPath({ path: data?.currentPage?.path });
  useOnFocusable(onFocusable, !isLoading);

  // Set previous page in funnel store, necessary when exiting (back/close) the funnel
  const { previousPage } = data || {};
  useEffect(() => {
    if (previousPage) {
      funnelDispatch(setPreviousPage(previousPage));
    }
  }, [funnelDispatch, previousPage]);

  // Set loading state according to isFetching
  useEffect(() => {
    funnelDispatch(setIsLoading(isFetching));
  }, [funnelDispatch, isFetching]);

  const renderContent = useCallback(() => {
    const displayTemplate = data?.currentPage?.displayTemplate;
    // Only change render according to current `data` displayTemplate
    switch (displayTemplate) {
      case FunnelHodorStep.ContextualOffer:
        return <ContextualOffer data={data} onFocusable={onFocusable} />;
      case FunnelHodorStep.PaymentMeans:
        return <PaymentMeans from={Template.FunnelContent} data={data} onFocusable={onFocusable} />;
      default:
        return null;
    }
  }, [data, onFocusable]);

  /**
   * Response hodor when there are an error
   */
  if (errorTemplate) {
    const renderWrapperButton = ({ id, className, children, disabled }) => (
      <Linker
        id={id}
        // funnel tvod only work on immersive 'openImmersive'
        data={{ mainOnClick: errorTemplate?.button?.onClick, ...(isImmersive && { context: 'openImmersive' }) }}
        onClick={() => {
          // Safety measure when error twice on same content
          funnelDispatch(setIsOpen(false));
        }}
        className={className}
        disabled={disabled}
      >
        {children}
      </Linker>
    );

    return (
      <div className={styles.funnelContent__wrapper}>
        <ErrorTemplate
          title={errorTemplate?.currentPage?.displayName}
          buttonLabel={errorTemplate?.button?.label}
          className={classNames(styles.funnelContent__content, styles['funnelContent__content--error'])}
          alertBox={errorTemplate?.alertBox}
          renderWrapperButton={currentStep.hodorStep === FunnelHodorStep.PaymentError ? renderWrapperButton : undefined}
        />
      </div>
    );
  }

  if (error) {
    return <ErrorTemplate error={error} />;
  }

  return (
    <div
      className={classNames(styles.funnelContent__wrapper, {
        [styles['funnelContent__wrapper--no-top-padding']]: isTermsOfSale,
      })}
    >
      <div className={styles.funnelContent__content}>{renderContent()}</div>
    </div>
  );
}

export default FunnelContent;
