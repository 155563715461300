import { MinorOffer } from '@canalplus/mycanal-sharedcomponent';
import { mapStaticKey } from '@canalplus/mycanal-util-react';
import { ApiV2ContextualStrateOffer } from '@dce-front/hodor-types/api/v2/contextual_offer_page/definitions';
import classNames from 'classnames';
import { OnClickHodorType } from '../../../../../templates/FunnelTvod/helpers/types';
import styles from './MinorOffers.css';

export type MinorOffersProps = {
  /** custom className */
  className?: string;

  /** list of minorOffers */
  minorOffers?: ApiV2ContextualStrateOffer[];

  /** function triggered when user clicking on the component */
  onClick: ({ onClick }: OnClickHodorType) => void;
};

/** This component is used to display all MinorOffers */
export function MinorOffers({ className, minorOffers, onClick }: MinorOffersProps): JSX.Element {
  const minorOfferWithHash = mapStaticKey(minorOffers, 'contentID');

  return (
    <div className={classNames(styles.minorOffers, className)} data-testid="minor-offers">
      {minorOfferWithHash.map(({ onClick: onClickHodor, prices, title, subtitle, hash }) => {
        return (
          <MinorOffer
            key={hash}
            className={styles.minorOffers__item}
            prices={prices}
            onClick={() => onClick({ onClick: onClickHodor })}
            subtitle={subtitle}
            title={title}
          />
        );
      })}
    </div>
  );
}
