import { MajorOffer } from '@canalplus/mycanal-sharedcomponent';
import { ApiV2ContextualStrateOffer } from '@dce-front/hodor-types/api/v2/contextual_offer_page/definitions';
import classNames from 'classnames';
import I18n from '../../../../../lang';
import { OnClickHodorType } from '../../../../../templates/FunnelTvod/helpers/types';
import styles from './MajorOffers.css';

type MajorOffersProps = {
  /** custom className */
  className?: string;

  /** is from tvDevice */
  isTvDevice?: boolean;

  /** list of majors */
  majorOffers: ApiV2ContextualStrateOffer[];

  /** function triggered when user clicking on the component */
  onClick: ({ onClick }: OnClickHodorType) => void;
};

/** This component is used to display all MajorOfferItems */
export function MajorOffers({ className, isTvDevice = false, majorOffers, onClick }: MajorOffersProps): JSX.Element {
  const { t } = I18n.useTranslation();
  return (
    <div className={classNames(styles.majorOffers, className)} data-testid="major-offers">
      {majorOffers.map(({ onClick: onClickHodor, prices, subtitle, title, shortTitle }, index) => {
        const pricesLabel = prices?.promotionalPriceLabel
          ? `${prices?.promotionalPriceLabel} ${t('FunnelTvod.insteadOf')} ${prices?.initialPriceLabel}`
          : prices?.initialPriceLabel;

        return (
          <MajorOffer
            shortTitle={shortTitle}
            // do not use "mapStaticKey" here to avoid rerender each time the hash is changed,
            // use "index" instead, until "mapStaticKey" is refactored
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            prices={prices}
            onClick={() => onClick({ onClick: onClickHodor })}
            subtitle={subtitle}
            hasClickOnCard={isTvDevice}
            title={title}
            ariaLabelPrices={pricesLabel}
          />
        );
      })}
    </div>
  );
}
