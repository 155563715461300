import styles from './VoucherInput.css';

type VoucherInputProps = {
  placeHolderLabel: string;
  value: string;
};

export function VoucherInput({ placeHolderLabel, value }: VoucherInputProps): JSX.Element {
  return (
    <input
      className={styles.voucherInput__input}
      value={value}
      aria-label={placeHolderLabel}
      placeholder={placeHolderLabel}
      readOnly
    />
  );
}
