import { Button, LinkTrashSvg, List, ListGroup, RadioGroup, RadioGroupProps } from '@canalplus/dive';
import classNames from 'classnames';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PaymentMeanCode } from '../../../../../constants/tvod';
import I18n from '../../../../../lang';
import { displayTVModeSelector } from '../../../../../store/slices/displayMode-selectors';
import { FunnelInnerStep, SOURCE_TURBO } from '../../../../../templates/FunnelTvod/stores/constants';
import {
  addPaymentMean,
  incrementFunnelHistory,
  setCurrentStep,
} from '../../../../../templates/FunnelTvod/stores/funnel/actions';
import {
  useFunnelCurrentStep,
  useFunnelDispatch,
  useFunnelPaymentMean,
} from '../../../../../templates/FunnelTvod/stores/funnel/hooks';
import { PaymentMean } from '../../../../../templates/FunnelTvod/stores/types';
import styles from './CrmInformation.css';

type CrmInformationStandaloneProps = {
  /** source CRM kiss or turbo */
  source?: string;
  /** paymentMean */
  paymentMean: PaymentMean;
};

function CrmInformationStandalone({ source, paymentMean }: CrmInformationStandaloneProps): JSX.Element {
  const isTurboSource = source === SOURCE_TURBO;
  const { paymentMeanLabel: label, paymentMeanInfo, contractPaymentMeanInfo } = paymentMean;
  const info = isTurboSource ? paymentMeanInfo : contractPaymentMeanInfo;

  return (
    <div className={styles.CrmInformation}>
      <div key={`paymentMeans-${label}-${info}`} className={classNames(styles.CrmInformation__item)}>
        <div className={styles.CrmInformation__item__label}>{label}</div>
        {info && <div className={styles.CrmInformation__item__subLabel}>{info}</div>}
      </div>
    </div>
  );
}

type CrmInformationTvProps = {
  source?: string;
  paymentMeans: PaymentMean[];
};

function CrmInformationTv({ source, paymentMeans }: CrmInformationTvProps): JSX.Element {
  const funnelDispatch = useFunnelDispatch();
  const currentStep = useFunnelCurrentStep();
  const isTurboSource = source === SOURCE_TURBO;
  const { t } = I18n.useTranslation();
  const paymentMeanCBR = paymentMeans.find((paymentMean) => paymentMean.paymentMeanCode === PaymentMeanCode.TVOD_CB);

  return (
    <div className={styles.CrmInformation}>
      <ListGroup>
        {paymentMeans?.map((paymentMean, index) => {
          const { paymentMeanLabel: label, paymentMeanInfo, contractPaymentMeanInfo } = paymentMean;
          const info = isTurboSource ? paymentMeanInfo : contractPaymentMeanInfo;
          return (
            <List
              key={`paymentMeans-${label}-${info}`}
              id={`paymentMeans${index}`}
              title={label || ''}
              label={info}
              displayChevron
              onClick={() => {
                const newStep = { ...currentStep, innerStep: FunnelInnerStep.EndPayment };
                funnelDispatch(addPaymentMean(paymentMean));
                funnelDispatch(setCurrentStep(newStep));
                funnelDispatch(incrementFunnelHistory(newStep));
              }}
            />
          );
        })}
      </ListGroup>
      {paymentMeanCBR && (
        <Button
          font="hind"
          variant="text"
          onClick={() => {
            const newStep = { ...currentStep, innerStep: FunnelInnerStep.DeleteCB };
            funnelDispatch(setCurrentStep(newStep));
            funnelDispatch(incrementFunnelHistory(newStep));
          }}
          className={styles.CrmInformation__deleteButton}
        >
          {t('FunnelTvod.deleteCBRegistered')}
        </Button>
      )}
    </div>
  );
}

type CrmInformationDesktopProps = {
  setShowDeleteCBModal?: Dispatch<SetStateAction<boolean>>;
  source?: string;
  paymentMeans: PaymentMean[];
};

function CrmInformationDesktop({
  setShowDeleteCBModal,
  source,
  paymentMeans,
}: CrmInformationDesktopProps): JSX.Element {
  const funnelDispatch = useFunnelDispatch();
  const selectedPaymentMean = useFunnelPaymentMean();
  const isTurboSource = source === SOURCE_TURBO;
  const { t } = I18n.useTranslation();

  /** Format options accordingly to available paymentMeans
   * @warning Keep this memoized to avoid re-rendering options, which avoids re-calculation
   * of the selected option on mount of the RadioGroup. To know which option is selected,
   * use onChange callback instead.
   */
  const options: RadioGroupProps['options'] = useMemo(
    () =>
      paymentMeans?.map((paymentMean, index) => {
        const {
          paymentMeanLabel: label,
          // Turbo
          paymentMeanInfo,
          // Kiss
          contractPaymentMeanInfo,
          paymentMeanCode,
        } = paymentMean;
        // Check if the payment mean is the selected one
        const isChecked = paymentMean === selectedPaymentMean;
        const subLabel = isTurboSource ? paymentMeanInfo : contractPaymentMeanInfo;
        const isPaymentMeanCBR = paymentMeanCode === PaymentMeanCode.TVOD_CB;
        return {
          name: 'paymentMeanCrm',
          isVerticalSubLabel: !!subLabel,
          label: label || '',
          subLabel,
          value: `${index}`,
          isChecked,
          ...(isPaymentMeanCBR && {
            actionableElement: (
              <Button
                variant="text"
                width="fit"
                icon={<LinkTrashSvg className={styles.CrmInformation__item__deleteIcon} />}
                onClick={() => setShowDeleteCBModal?.(true)}
                font="hind"
              >
                {t('FunnelTvod.deleteCBRegisteredBtn')}
              </Button>
            ),
          }),
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentMeans]
  );

  return (
    <div className={styles.CrmInformation}>
      <RadioGroup
        className={styles.CrmInformation__item}
        id="paymentMeanCrmInformation"
        onChange={(value) => {
          const [selectedOption] = paymentMeans.filter((_, index) => value === `${index}`);
          if (selectedOption) {
            funnelDispatch(addPaymentMean(selectedOption));
          }
        }}
        options={options}
      />
    </div>
  );
}

export type CrmInformationProps = {
  setShowDeleteCBModal?: Dispatch<SetStateAction<boolean>>;
  source?: string;
  paymentMeans: PaymentMean[];
};

/**
 * TVOD Urba component to display the CRM information (payment means) in the funnel.
 * The data is displayed differently depending on the device (web, oneDiscovery), and
 * according to the type of source (turbo, kiss).
 * @returns Crm information component
 */
export function CrmInformation({ setShowDeleteCBModal, source, paymentMeans }: CrmInformationProps): JSX.Element {
  const isTvDevice = useSelector(displayTVModeSelector);

  if (paymentMeans.length === 1) {
    const [singlePaymentMean] = paymentMeans;
    return <CrmInformationStandalone source={source} paymentMean={singlePaymentMean} />;
  }

  return isTvDevice ? (
    <CrmInformationTv source={source} paymentMeans={paymentMeans} />
  ) : (
    <CrmInformationDesktop setShowDeleteCBModal={setShowDeleteCBModal} source={source} paymentMeans={paymentMeans} />
  );
}
