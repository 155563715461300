import { useCallback } from 'react';
import { useAppHistory } from '../../../../../helpers/hooks/reactRouter';
import { FunnelHodorStep } from '../../../../../templates/FunnelTvod/stores/constants';
import {
  amendFunnelHistory,
  decrementFunnelHistory,
  setCurrentStep,
  setErrorTemplate,
} from '../../../../../templates/FunnelTvod/stores/funnel/actions';
import {
  useFunnelCurrentStep,
  useFunnelDispatch,
  useFunnelHistory,
  useFunnelPreviousPage,
} from '../../../../../templates/FunnelTvod/stores/funnel/hooks';
import { FunnelStep } from '../../../../../templates/FunnelTvod/stores/types';

/**
 * Handle funnel modal **onBack** behavior
 * - Decrement funnel history if possible and set current step to the previous one
 * - If it's the first step, close the modal
 */
export const useHandleFunnelModalBack = (onClose: () => void, fullPage = false): (() => void) => {
  const history = useAppHistory();
  const funnelDispatch = useFunnelDispatch();
  const currentStep = useFunnelCurrentStep();
  const funnelHistory = useFunnelHistory();
  const previousPage = useFunnelPreviousPage();

  return useCallback(() => {
    funnelDispatch(setErrorTemplate());
    const isFirstStep = funnelHistory?.length === 1;
    if (isFirstStep) {
      if (!fullPage) {
        // (CSR) Close the modal
        onClose();
        return;
      }

      // (SSR) Redirect to...
      if (currentStep.hodorStep === FunnelHodorStep.ContextualOffer) {
        // (SSR) ... Detail page if we are on ContextualOffer
        history.push(previousPage?.path || '..', {
          page: {
            mainOnClick: previousPage,
          },
        });
      } else {
        // (SSR) ... ContextualOffer page if we are on PaymentMeans
        const initStep: FunnelStep = {
          url: previousPage?.URLPage,
          hodorStep: previousPage?.displayTemplate as FunnelHodorStep,
        };
        funnelDispatch(amendFunnelHistory(initStep));
        funnelDispatch(setCurrentStep(initStep));
      }
    } else {
      // Decrement funnel history
      funnelDispatch(decrementFunnelHistory());
    }
  }, [currentStep, funnelHistory, previousPage, history, fullPage, funnelDispatch, onClose]);
};
