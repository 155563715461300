import { Alert, AlertStatus, Button } from '@canalplus/dive';
import { getQsValueByKey } from '@canalplus/mycanal-commons';
import { TvodTitle } from '@canalplus/mycanal-sharedcomponent';
import { Binder, useStore } from '@canalplus/one-navigation';
import { ApiV2TvodTracking } from '@dce-front/hodor-types/api/v2/common/dto/tvod/defintions';
import {
  ApiV2TvodPayment,
  ApiV2TvodPaymentError,
  ApiV2TvodPaymentState,
} from '@dce-front/hodor-types/api/v2/tvod/payment/definitions';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { XOR } from 'ts-essentials';
import { MutationKeys } from '../../../../constants/mutationKeys';
import { QueryKeys } from '../../../../constants/queryKeys';
import { useAppHistory } from '../../../../helpers/hooks/reactRouter';
import { useAppDispatch } from '../../../../helpers/hooks/useAppDispatch';
import { LAYER_VOD_FUNNEL } from '../../../../helpers/oneNavigation/layers';
import I18n from '../../../../lang';
import { getFeatureToggleTvodUrbaQRCode } from '../../../../selectors/application/application-selectors';
import { displayTVModeSelector } from '../../../../store/slices/displayMode-selectors';
import { PurchaseCodeState, updatePurchaseInfo } from '../../../../store/slices/purchaseCode';
import { QS_PROCESS_STATE, TVOD_ERROR, TVOD_SUCCESS } from '../../helpers/const';
import { FunnelHodorStep, HapiStatus } from '../../stores/constants';
import {
  amendFunnelHistory,
  setCurrentStep,
  setErrorTemplate,
  setIsOpen as setIsOpenAction,
} from '../../stores/funnel/actions';
import { useFunnelDispatch, useFunnelPaymentMean } from '../../stores/funnel/hooks';
import { useHapiStatus } from '../PaymentMeans/hooks/useHapiStatus/useHapiStatus';
import styles from './DeportedPayment.css';

const TIMEOUT_REDIRECT = 3_000;

export type DeportedPaymentProps = {
  /** Payment state from Hodor with status based on id = success / error */
  currentContent?: ApiV2TvodPaymentState[];
  /** PurchaseId to fetch Hapi status */
  purchaseId?: string;
  /** Tracking data */
  tracking?: ApiV2TvodTracking;
};

/**
 * DeportedPayment
 * Display deported payment step
 */
export function DeportedPayment({ currentContent, purchaseId, tracking }: DeportedPaymentProps): JSX.Element | null {
  const { t } = I18n.useTranslation();
  const showQrCode = useSelector(getFeatureToggleTvodUrbaQRCode);
  const [isPulling, setIsPulling] = useState(showQrCode); // Initial state based on QR code feature toggle
  const [orderStatus, setOrderStatus] = useState<string>();
  const [imageIsLoaded, setImageIsLoaded] = useState(false);
  const [userHasTriggerReload, setUserHasTriggerReload] = useState(false);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  // Fetch Hapi status
  const fetchHapiStatus = useHapiStatus(purchaseId);
  // Fetch POST redirectPayment
  const mutation = useMutation<XOR<ApiV2TvodPayment, ApiV2TvodPaymentError>>({
    mutationKey: [MutationKeys.RedirectPayment],
  });
  // Data from POST redirectPayment
  const [data, setData] = useState<XOR<ApiV2TvodPayment, ApiV2TvodPaymentError>>();
  const history = useAppHistory();
  const funnelDispatch = useFunnelDispatch();
  const isTvDevice = useSelector(displayTVModeSelector);
  const store = useStore();

  const { detail, pageParameters, refreshInformation } = data || {};
  const { maxRetryDuration, retryIntervalDuration } = pageParameters || {};
  const { title, subtitle, URLQRCode } = detail || {};
  const { automaticDescription, manualDescription } = refreshInformation || {};

  const orderIsCompleted = orderStatus === HapiStatus.Complete;
  const orderIsCanceled = orderStatus === HapiStatus.Cancel;
  const orderIsPending = orderStatus === HapiStatus.Pending || orderStatus === HapiStatus.Incomplete;

  const selectedPaymentMean = useFunnelPaymentMean();

  // check purchase process status hAPI
  // Refetch each 15s (retryIntervalDuration) and display CTA "Refresh" (120s)
  // Refetch when CTA "refresh" is clicked
  const { data: dataFetch, refetch } = useQuery({
    queryKey: [purchaseId],
    queryFn: fetchHapiStatus,
    refetchInterval: retryIntervalDuration,
    enabled: isPulling,
    gcTime: 0,
  });

  /**
   * Handle the reload process, including updating queries and managing redirection based on order status
   */
  const handleReload = useCallback(async () => {
    /**
     * Refetch on Success
     * DetailPage : Refetch detail that contain object popin hodor to display popin success
     * DetailActionLayout : Refetch perso to have Play button
     * DetailEpisodes : Refetch episode list for series
     */
    await queryClient.invalidateQueries({ queryKey: [QueryKeys.DetailPage] });
    await queryClient.invalidateQueries({ queryKey: [QueryKeys.DetailActionLayout] });
    await queryClient.invalidateQueries({ queryKey: [QueryKeys.DetailEpisodes] });

    // When currentContent is not defined we close the funnel
    // Case hodor didn't return any state for the payment
    if (!currentContent) {
      funnelDispatch(setIsOpenAction(false));
      return;
    }
    // Find the callback URLPage to redirect to the detail page according order status
    const paymentCallbackState = currentContent.find((content: ApiV2TvodPaymentState) =>
      orderIsCanceled ? content.id === TVOD_ERROR : content.id === TVOD_SUCCESS
    )?.callback;

    // Redirect to the detail page
    if (paymentCallbackState?.URLPage) {
      // Determine the path based on the canceled or completed flag
      const currentContentRedirect = {
        ...paymentCallbackState,
        URLPage: paymentCallbackState.URLPage,
        path:
          orderIsCanceled || orderIsCompleted
            ? `${paymentCallbackState.path}?${QS_PROCESS_STATE}=${getQsValueByKey(
                paymentCallbackState.URLPage,
                QS_PROCESS_STATE
              )}`
            : `${paymentCallbackState.path}`,
      };

      // Push the new state to the history only if we have an orderStatus
      if (orderStatus) {
        const purchaseInfo: PurchaseCodeState['purchaseInfo'] = {
          purchaseId: tracking?.dataLayer?.purchase_id,
          paymentMethod: selectedPaymentMean?.paymentMeanLabel,
          productCategory: tracking?.dataLayer?.product_category,
          productPrice: tracking?.dataLayer?.product_price,
        };
        dispatch(updatePurchaseInfo(purchaseInfo));

        history.replace(currentContentRedirect.path, {
          ...history.location?.state,
          immersive: {
            ...history.location?.state?.immersive,
            mainOnClick: currentContentRedirect,
          },
        });
        funnelDispatch(setIsOpenAction(false));
      }
    }
  }, [
    queryClient,
    currentContent,
    funnelDispatch,
    orderIsCanceled,
    orderIsCompleted,
    orderStatus,
    tracking,
    selectedPaymentMean,
    dispatch,
    history,
  ]);

  /**
   * Fetch POST redirectPayment when the component mounts
   * use Mutation that is prepared previously with queryClient
   */
  useEffect(() => {
    async function fetchData() {
      const response = await mutation.mutateAsync();
      setData(response);
      return response;
    }
    fetchData().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handle Hapi response according status order & if the user has clicked on refresh button
   * On success or cancel order => Redirect to Detail with a contextual modal (Success or Error)
   * On pending order => Redirect to Detail without modal
   */
  useEffect(() => {
    setOrderStatus(dataFetch?.status);

    async function reload() {
      await handleReload();
    }

    if (userHasTriggerReload) {
      reload().catch(console.error);
    } else if (orderIsCompleted || orderIsCanceled) {
      if (isPulling) {
        setIsPulling(false);
      }
      const timeout = setTimeout(() => {
        reload().catch(console.error);
      }, TIMEOUT_REDIRECT);
      return () => clearTimeout(timeout);
    }
    return;
  }, [dataFetch, handleReload, orderIsCompleted, orderIsCanceled, isPulling, userHasTriggerReload]);

  /**
   * Pulling timer during maxRetryDuration
   */
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (maxRetryDuration) {
        setIsPulling(false);
      }
    }, maxRetryDuration);

    return () => clearTimeout(timeout);
  }, [maxRetryDuration]);

  /**
   * Display payment error step if necessary
   */
  useEffect(() => {
    if (data?.currentPage?.displayTemplate === FunnelHodorStep.PaymentError) {
      const newStep = {
        hodorStep: FunnelHodorStep.PaymentError,
      };
      funnelDispatch(amendFunnelHistory(newStep));
      funnelDispatch(setCurrentStep(newStep));
      funnelDispatch(setErrorTemplate(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Set active layer to VOD_FUNNEL on TV device in case our previous step is "purchaseCode"
  useEffect(() => {
    if (isTvDevice) {
      store.setActiveLayer(LAYER_VOD_FUNNEL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoad = () => {
    setImageIsLoaded(true);
  };

  /**
   * Return null if there's a payment error or no data
   */
  if (data?.currentPage?.displayTemplate === FunnelHodorStep.PaymentError || !data) {
    return null;
  }

  /**
   * Display only title & alert message when "featTvodUrbaQRCode" equal false
   */
  if (!showQrCode) {
    return (
      <div className={classNames(styles.deportedPayment, styles['deportedPayment--center'])}>
        <TvodTitle
          className={classNames([styles.deportedPayment__title], [styles['deportedPayment__title--center']])}
          title={t('FunnelTvod.CBDisabledTitle')}
        />
        <Alert status={AlertStatus.Warning} message={t('FunnelTvod.CBDisabledAlert')} />
      </div>
    );
  }

  return (
    <div
      className={classNames(styles.deportedPayment, {
        [styles['deportedPayment--hidden']]: !imageIsLoaded,
      })}
    >
      <div className={styles.deportedPayment__header}>
        {title && (
          <TvodTitle
            className={styles.deportedPayment__title}
            classNameSubtitle={styles.deportedPayment__subtitle}
            title={title}
            subtitle={subtitle}
          />
        )}
        <img className={styles.deportedPayment__QRCode} src={URLQRCode} onLoad={onLoad} />
      </div>
      <div className={styles.deportedPayment__footer}>
        {isPulling && orderIsPending && (
          <div className={styles.deportedPayment__description}>{automaticDescription}</div>
        )}

        {!isPulling && orderIsPending && (
          <>
            <div className={styles.deportedPayment__description}>{manualDescription}</div>
            <Binder className={styles.deportedPayment__binder} forceFocusOnMount>
              <Button
                className={styles.deportedPayment__button}
                variant="tertiary"
                width="fixed"
                onClick={async () => {
                  setUserHasTriggerReload(true);
                  await refetch();
                }}
                font="hind"
              >
                {t('FunnelTvod.reload')}
              </Button>
            </Binder>
          </>
        )}
      </div>
    </div>
  );
}
