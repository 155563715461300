import { FromProp } from '../../../server/modules/fetchWithQuery/types';
import { FunnelHodorStep } from '../stores/constants';
import { FunnelProvider } from '../stores/funnel/context';
import { FunnelModal } from './FunnelModal';

type FunnelTvodProps = {
  /** path url */
  url: string;
  /** is SSR mode */
  fullPage?: boolean;
  /** step hodor in funnel */
  hodorStep?: FunnelHodorStep;
  /** set open modal */
  setIsOpen?: (openModal: boolean) => void;
} & FromProp;

function FunnelTvod({
  from,
  hodorStep = FunnelHodorStep.ContextualOffer,
  url,
  fullPage = false,
  setIsOpen,
}: FunnelTvodProps): JSX.Element {
  const initialStep = { url, hodorStep };
  return (
    <FunnelProvider initialStep={initialStep}>
      <FunnelModal from={from} fullPage={fullPage} setIsOpen={(openModal) => setIsOpen?.(openModal)} />
    </FunnelProvider>
  );
}

export default FunnelTvod;
