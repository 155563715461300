import { useGetTermsOfSaleUrl } from '../../../../../helpers/hooks/useGetTermsOfSaleUrl';
import { FromProp } from '../../../../../server/modules/fetchWithQuery/types';
import LoadableTextBrut from '../../../../../templates/TextBrut';

type TermsOfSaleProps = {
  onFocusable?: () => void;
} & FromProp;

export function TermsOfSale({ from, onFocusable }: TermsOfSaleProps): JSX.Element {
  const termsOfSaleUrl = useGetTermsOfSaleUrl();

  return <LoadableTextBrut from={from} url={termsOfSaleUrl} onFocusable={onFocusable} />;
}
