import { ApiV2CrmInformation } from '@dce-front/hodor-types/api/v2/tvod/payment_means/definitions';
import { useMemo } from 'react';
import { sortByPaymentType } from '../../../../../../templates/FunnelTvod/helpers/utils';
import { SOURCE_TURBO } from '../../../../../../templates/FunnelTvod/stores/constants';
import { CrmResponseProxyData, PaymentMean } from '../../../../../../templates/FunnelTvod/stores/types';

/**
 * useSortedAvailablePaymentMeans hook that return sorted available payment means (IBANs first) according to source
 * @param crmInformation - crmInformation from hodor
 * @returns available payment means
 */
export const useSortedAvailablePaymentMeans = (crmInformation?: ApiV2CrmInformation): PaymentMean[] | null => {
  const crmResponseProxyCasted = crmInformation?.crmResponseProxy as CrmResponseProxyData;
  const { data } = crmResponseProxyCasted || {};
  const isTurboSource = crmInformation?.source === SOURCE_TURBO;
  const paymentMeans: PaymentMean[] = isTurboSource ? data?.paymentMeans : data?.availablePaymentMeans;

  const sortedPaymentMeans = useMemo(() => {
    if (!crmInformation?.crmResponseProxy?.returnCode && isTurboSource) {
      return null;
    }
    // ! For TIM, there are only IBANs and no PaymentCard, so no need to sort paymentMeans
    // ! Once Turbo is used for Metropole, we should always sort paymentMeans
    return isTurboSource ? paymentMeans || [] : sortByPaymentType(paymentMeans || []);
  }, [crmInformation?.crmResponseProxy?.returnCode, isTurboSource, paymentMeans]);
  return sortedPaymentMeans;
};
