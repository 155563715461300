import { StoreLayer } from '../store/StoreLayer';
import { useLayerId } from './useLayerId';
import { useStore } from './useStore';

/**
 * Retrieves specific layer by id or context layer by default
 * @param layerId id of the layer to be retrieved
 * @returns layer object
 */
export function useLayer(layerId?: number): StoreLayer {
  const store = useStore();
  const layerContextId = useLayerId();

  return store.getLayer(typeof layerId !== 'undefined' ? layerId : layerContextId);
}
